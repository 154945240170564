<template>
  <b-navbar v-if="getUsername" toggleable="sm" type="dark" variant="dark">

    <b-navbar-brand href="https://ujjk.se">
      <img src="../assets/logo_60x60.png" width="30" height="30" class="d-inline-block align-top" alt="Logo">
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item v-if="user.email !== 'inst@ujjk.se'" to="/profile">Medlemskap</b-nav-item>
        <b-nav-item v-if="user.instructor" to="/groups">Närvaro</b-nav-item>
        <!-- <b-nav-item v-if="user.instructor" to="/anmalan">Ny anmälan</b-nav-item> -->
        <b-nav-item v-if="user.instructor" to="/intressenter">Anmälningar</b-nav-item>
        <b-nav-item v-if="user.instructor" to="/promotions">Graderingar</b-nav-item>
        <b-nav-item v-if="user.instructor" to="/staff">Funktionärer</b-nav-item>
        <b-nav-item-dropdown v-if="user.instructor || user.admin" text="Ekonomi">
          <b-dropdown-item to="/members">Medlemmar</b-dropdown-item>
          <b-dropdown-item to="/belts">Bälten</b-dropdown-item>
          <b-dropdown-item v-if="user.admin" to="/attendance">Närvaro</b-dropdown-item>
          <b-dropdown-item v-if="user.admin" to="/reports">Rapporter</b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown v-if="user.admin" text="Admin">
          <b-dropdown-item to="/payments">Betalningar</b-dropdown-item>
          <b-dropdown-item to="/ranks">Grader</b-dropdown-item>
          <b-dropdown-item to="/functions">Funktionärer</b-dropdown-item>
          <b-dropdown-item to="/users">Användare</b-dropdown-item>
          <b-dropdown-item to="/cards">Kort</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown right>
          <!-- Using 'button-content' slot -->
          <template v-slot:button-content>
            {{ getUsername }}
          </template>
          <b-dropdown-item href="#" @click="logout">Logga ut</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>

  </b-navbar>
</template>

<script>
  import { mapActions, mapGetters, mapState } from 'vuex';

  export default {
    computed: {
      ...mapState('user', ['user']),
      ...mapGetters('user', ['getUsername'])
    },
    methods: {
      ...mapActions({logout: 'user/logoutAction'})
    }
  }
</script>
